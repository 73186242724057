
import { Component, Vue, Watch } from 'vue-property-decorator'
import HeaderClient from './HeaderClient.vue'
import ResponsableDialogComponent from '@/components/ResponsableDialog/Index.vue'
import EditUserModal from '@/components/ResponsableDialog/EditUserModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog/Index.vue'
import { mapGetters } from 'vuex'
import Avatar from 'vue-avatar'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import ClickOutside from 'vue-click-outside'
import { ContactsInterface } from '@/store/types/Business/Contacts'
import { Roles, SelectInterface } from '@/utils/Select'
import { UsersInterface } from '@/store/types/Business/Users'

@Component({
  components: {
    HeaderClient,
    ConfirmDialog,
    ResponsableDialogComponent,
    Avatar,
    OverlayBackground,
    EditUserModal,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    ...mapGetters({
      Business: 'BusinessModule/getSelectedProduct',
      ContactModal: 'BusinessModule/getModalStatus',
      relieved: 'BusinessModule/getRelivedFramework',
      FrameworkSelected: 'PolimorficModule/getFrameworkSelected',
    }),
    ...mapGetters('GapModule', ['getAdvancedGap']),
  },
})
export default class Contact extends Vue {
  private Business!: any
  private BusinessId = this.$store.getters['AuthModule/getCompanyIdSelected']
  private selected: number | null = null
  private showSelect = false
  private showModal = false
  private showConfirmModal = false
  private showEditUserModal = false
  private title = ''
  private test = false
  private new_main_contact_id: null | number = null
  private subtitle = ''
  private typeAction = 0
  private action = ''
  private selectedUserId = ''
  private page = 0
  private totalPageScroll = 0
  private flagList = false
  private auxUserList: any
  private selectedId = 0
  private modelguid = {}
  private activeRoles: Array<SelectInterface> = []
  private selectedContact: ContactsInterface = {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    roles: [],
  }
  private selectedUser: UsersInterface = {
    given_name: '',
    family_name: '',
    email: '',
  }
  private loading = false
  private async mounted() {
    // await this.$store.dispatch("GapModule/getGapCategories", this.BusinessId).then(() => {
    //   this.Business.id = this.BusinessId;
    // });
    window.addEventListener('scroll', this.handleScroll)
    this.Business.id = this.BusinessId
    this.Business.users = []

    this.getActiveRoles()
    this.getContact(null)
    this.getUser(null)
    this.getmodal();
  }

  private async beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  @Watch('ContactModal')
  testModalOpen(): void {
    this.openModal(1)
  }

  //Modal Methods

  private open(user_id: number) {
    setTimeout(() => {
      this.selected = user_id
    }, 10)
  }

  private close() {
    this.selected = null
  }

  private modalAction() {
    if (this.typeAction == 2) {
      this.unblockCompanyUserId(this.selectedUserId)
    }

    if (this.typeAction == 3) {
      this.blockCompanyUserId(this.selectedUserId)
    }

    if (this.typeAction == 4) {
      this.deleteItem()
    }
  }

  private openModal(type: number, tr?: UsersInterface) {
    if (tr) {
      this.selectedUserId = tr.user_id ? tr.user_id : ''
    }
    this.typeAction = type
    this.showSelect = false
    this.selected = null
    switch (type) {
      case 1:
        this.showModal = true
        break
      case 2:
        this.showConfirmModal = true
        this.title = '¿Desea activar esta cuenta?'
        this.subtitle = 'Se le habilitará el acceso a la plataforma'
        this.action = 'Activar cuenta'
        break
      case 3:
        this.showConfirmModal = true
        this.title = '¿Desea desactivar usuario?'
        this.action = 'Desactivar'
        this.subtitle =
          'No se eliminarán los datos del usuario pero se le inhabilitará el acceso a la plataforma.'

        break
      case 4:
        this.showConfirmModal = true
        this.action = 'Eliminar'
        this.title = '¿Estás seguro?'
        this.subtitle =
          'Se eliminarán todos los datos del usuario. <br>Está acción no podrá ser revertida'

        break
    }
  }

  private closeModal() {
    this.selectedId = 0
    this.showModal = false
    this.showConfirmModal = false
    this.showEditUserModal = false
    this.selectedContact = {
      name: '',
      last_name: '',
      email: '',
      phone: '',
      roles: [],
    }
    this.selectedUser = {
      given_name: '',
      family_name: '',
      email: '',
    }
  }

  //Users Methods

  private blockCompanyUserId(user_id: string) {
    this.showConfirmModal = false
    this.loading = true
    this.$store
      .dispatch('BusinessModule/blockCompanyUserId', user_id)
      .then(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Usuario desactivado con éxito`,
          timeout: 2000,
        })
        let user = this.Business.users.find((user: UsersInterface) => user.user_id === user_id)
        if (user) {
          user.status = 'DISABLED'
        }
      })
      .catch(() => {
        this.loading = false
        this.getUser(this.loading)
      })
  }

  private unblockCompanyUserId(user_id: string) {
    this.showConfirmModal = false
    this.loading = true
    this.$store
      .dispatch('BusinessModule/unblockCompanyUserId', user_id)
      .then(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Cuenta activada`,
          timeout: 2000,
        })
        let user = this.Business.users.find((user: UsersInterface) => user.user_id === user_id)
        if (user) {
          user.status = 'ACTIVE'
        }
      })
      .catch(() => {
        this.loading = false
        this.getUser(this.loading)
      })
  }

  private sendChangePasswordUser(tr: UsersInterface) {
    this.selected = null
    this.loading = true
    this.$store
      .dispatch('BusinessModule/sendChangePasswordUser', {
        email: tr.email,
      })
      .then(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Invitación enviada con éxito`,
        })
      })
      .catch(() => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error. Ha ocurrido un error inesperado',
          color_notification: 'danger',
        })
        this.getUser(this.loading)
      })
  }

  private handleScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (this.page < this.totalPageScroll - 1) {
        this.page++
        this.getUser(undefined, true)
      }
    }
  }

  private getUser(loading?: any, flag: boolean = this.flagList, page: number = this.page) {
    this.loading = true
    this.$store
      .dispatch('BusinessModule/getUsers', {
        data: [page, this.Business.id],
        flag: flag,
      })
      .then((data) => {
        this.totalPageScroll = data.data.data.pagination.totalPages
        if (this.loading) {
          this.loading = false
        }
        this.getActiveRoles()
      })
      .catch(() => {
        if (this.loading) {
          this.loading = false
        }
        this.getActiveRoles()
      })
  }
  private getmodal(){
    this.$store.dispatch('BusinessModule/getModules').then((data)=> {
      this.modelguid = data.data.data.map((item : any) => item.guid)
    })
  } 

  private openEditUserModal(selectedUser: UsersInterface) {
    this.selected = null
    this.selectedUser = selectedUser
    this.showEditUserModal = true
  }

  private handleUserUpdated({
    givenName,
    familyName,
    user_id,
  }: {
    givenName: string
    familyName: string
    user_id: string
  }) {
    let user = this.Business.users.find((user: UsersInterface) => user.user_id === user_id)
    if (user) {
      user.given_name = givenName
      user.family_name = familyName
    }
  }

  private handleContactCreated({ created, email }: { created: boolean; email: string }) {
    if (created === true) {
      this.auxUserList = this.getUser(undefined, undefined, 0)
      let newUser = this.auxUserList.users.find((user: UsersInterface) => user.email === email)
      if (newUser) {
        this.Business.users.push(newUser)
      }
    }
  }

  //Contacts Methods

  private activateContactId() {
    const body: any = {
      id: this.Business.id,
      contact_id: this.selectedId,
    }
    this.loading = true

    this.$store
      .dispatch('BusinessModule/activateContactId', body)
      .then(() => {
        this.getContact(this.loading)
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Cuenta activada',
          timeout: 2000,
        })
      })
      .catch(() => {
        this.getContact(this.loading)
      })
  }

  private deactivateContactId() {
    const user = this.Business.contacts.filter(
      (element: ContactsInterface) => element.id == this.selectedId
    )

    if (user[0].roles.indexOf('main') != -1 && this.new_main_contact_id == null) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Debe asignar un nuevo responsable principal para continuar',
        color_notification: 'danger',
      })
      return
    }

    this.showConfirmModal = false

    const body: any = {
      id: this.Business.id,
      contact_id: this.selectedId,
    }

    if (this.new_main_contact_id) {
      body['new_main_contact_id'] = this.new_main_contact_id
    }

    this.loading = true
    this.$store
      .dispatch('BusinessModule/deactivateContactId', body)
      .then(() => {
        this.getContact(this.loading)
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Usuario desactivado con éxito`,
          timeout: 2000,
        })
      })
      .catch(() => {
        this.getContact(this.loading)
      })
  }

  private getContact(loading?: any) {
    this.$store
      .dispatch('BusinessModule/getContacts', this.Business.id)
      .then(() => {
        if (this.loading) {
          this.loading = false
        }
        this.getActiveRoles()
      })
      .catch(() => {
        if (this.loading) {
          this.loading = false
        }
        this.getActiveRoles()
      })
  }

  private deleteItem() {
    const user = this.Business.contacts.filter(
      (element: ContactsInterface) => element.id == this.selectedId
    )

    if (user[0].roles.indexOf('main') != -1 && this.new_main_contact_id == null) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Debe asignar un nuevo responsable principal para continuar',
        color_notification: 'danger',
      })
      return
    }

    this.showConfirmModal = false

    const body: any = {
      id: this.Business.id,
      contact_id: this.selectedId,
    }

    if (this.new_main_contact_id) {
      body['new_main_contact_id'] = this.new_main_contact_id
    }
    this.loading = true
    this.$store
      .dispatch('BusinessModule/deleteContact', body)
      .then(() => {
        this.getContact(this.loading)
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Usuario eliminado con éxito',
          timeout: 2000,
        })
        this.new_main_contact_id = null
      })
      .catch(() => {
        this.new_main_contact_id = null
        this.getContact(this.loading)
      })
  }

  private getSelected(value: number) {
    this.new_main_contact_id = value
  }

  private send(tr: ContactsInterface) {
    this.selected = null
    this.loading = true
    this.$store
      .dispatch('BusinessModule/sendInvite', {
        id: this.Business.id,
        contact_id: tr.id,
      })
      .then(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Invitación enviada con éxito',
          timeout: 3000,
        })
        this.getContact(this.loading)
      })
      .catch(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Error inesperado',
          color_notification: 'danger',
        })
        this.getContact(this.loading)
      })
  }
  private reSend(tr: ContactsInterface) {
    this.selected = null
    this.loading = true
    this.$store
      .dispatch('BusinessModule/reSendInvite', {
        id: this.Business.id,
        contact_id: tr.id,
      })
      .then((data) => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Éxito, ${data.data.message}`,
        })

        this.getContact(this.loading)
      })
      .catch(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Error inesperado',
          color_notification: 'danger',
        })
        this.getContact(this.loading)
      })
  }

  private edit(selectedContact: ContactsInterface) {
    this.selected = null
    this.selectedContact = selectedContact
    this.showModal = true
  }

  private designate(tr: ContactsInterface) {
    this.selected = null
    const main_user = this.Business.contacts.filter(
      (element: ContactsInterface) => element.roles?.indexOf('main') != -1
    )

    if (main_user[0]?.roles.length == 1) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Debes asignarle un rol a tu responsable principal actual para continuar',
        color_notification: 'danger',
      })
      return
    }

    this.loading = true
    this.$store
      .dispatch('BusinessModule/SetCompanyMainContactId', {
        id: this.Business.id,
        contact_id: tr.id,
      })
      .then((response) => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Éxito, ${response.data.message}`,
        })
        this.getContact(this.loading)
      })
      .catch(() => {
        this.getContact(this.loading)
      })
  }

  getActiveRoles() {
    const aux: Array<SelectInterface> = Roles
    this.activeRoles = aux.slice(1)

    // if (!this.Business.contacts) {
    // this.activeRoles = aux.splice(0, 1)
    // }

    // aux.forEach((data: SelectInterface) => {
    //   let exist = false
    //   data['disabled'] = true
    //   this.Business.contacts.forEach((element: ContactsInterface) => {
    //     if (element.roles?.indexOf(data.value) != -1) {
    //       exist = true
    //     }
    //   })
    //   if (!exist) {
    //     data['disabled'] = false
    //   }
    // })

    // this.activeRoles = aux
  }

  get getContactsData() {
    if (this.Business.contacts) {
      return this.Business.contacts.filter(
        (element: ContactsInterface) => element.roles?.indexOf('main') == -1
      )
    } else {
      return []
    }
  }
}
