
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import InputComponent from '@/components/InputHackmetrix/Index.vue'
import SelectComponent from '@/components/SelectHackmetrix/Index.vue'
import { SelectInterface, Roles } from '@/utils/Select'
import * as validations from '@/utils/Validations'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import { UsersInterface } from '@/store/types/Business/Users'

@Component({
  components: { InputComponent, SelectComponent, OverlayBackground },
})
export default class EditUserModal extends Vue {
  @Prop({ required: true }) readonly showDialog!: string
  @Prop({ required: true }) readonly activeRoles!: Array<string>
  @Prop({ required: false }) selectedUser!: UsersInterface
  @Prop({ required: true }) id!: number
  @Prop({ required: true }) modelguid!: string

  private dialog = false
  private roles: SelectInterface[] = Roles
  private validator = validations
  private messageInfo = ''

  private user: UsersInterface = {
    given_name: '',
    family_name: '',
    email: '',
  }
  private loading = false

  private editUser() {
    if (!validations.regexEmail.test(this.user.email ?? '')) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error. Email no válido',
        color_notification: 'danger',
      })
      return
    }

    if (this.user.family_name == '' || this.user.given_name == '') {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error. Faltan campos por llenar',
        color_notification: 'danger',
      })
      return
    }

    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere. Actualizando...',
    })

    this.closeModal()
    this.loading = true

    const givenName = this.user.given_name
    const familyName = this.user.family_name
    const user_id = this.user.user_id
    let userEditData = {
      givenName: givenName,
      familyName: familyName,
      moduleGuids: this.modelguid,
    }
    this.$store
      .dispatch('BusinessModule/editCompanyUser', { user_id, userEditData })
      .then(() => {
        let message
        message = `Usuario ${givenName} editado con éxito`
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: message,
          timeout: 2000,
        })
        this.$emit('userUpdated', { givenName, familyName, user_id })
        this.$store
          .dispatch('BusinessModule/getUsers', this.id)
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {
        this.loading = false
      })
  }

  @Watch('showDialog')
  show(val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose(): void {
    if (this.dialog === false) {
      this.user = {
        given_name: '',
        family_name: '',
        email: '',
      }
      this.$emit('close')
    }
  }

  @Watch('selectedUser')
  changeUser(): void {
    this.user = { ...this.user, ...this.selectedUser }
  }

  private closeModal() {
    this.dialog = false
    this.$emit('close')
  }
}
