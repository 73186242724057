
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import InputComponent from '@/components/InputHackmetrix/Index.vue'
import SelectComponent from '@/components/SelectHackmetrix/Index.vue'
import { SelectInterface, Roles } from '@/utils/Select'
import * as validations from '@/utils/Validations'
import { ContactsInterface } from '@/store/types/Business/Contacts'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'

@Component({
  components: { InputComponent, SelectComponent, OverlayBackground },
})
export default class ResponsableDialogComponent extends Vue {
  @Prop({ required: true }) readonly showDialog!: string
  @Prop({ required: true }) readonly activeRoles!: Array<string>
  @Prop({ required: false }) selectedContact!: ContactsInterface
  @Prop({ required: true }) id!: number

  private dialog = false
  private roles: SelectInterface[] = Roles
  private validator = validations
  private messageInfo = ''
  private contact: ContactsInterface = {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    roles: [],
    send_invitation: true,
  }
  private loading = false

  private addContact() {
    if (!validations.regexEmail.test(this.contact.email)) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Email no válido',
        color_notification: 'danger',
      })
      return
    }

    if (
      this.contact.name == '' ||
      this.contact.last_name == '' ||
      this.contact.email == '' ||
      this.contact.roles?.length == 0
    ) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Faltan campos por llenar',
        color_notification: 'danger',
      })
      return
    }

    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Actualizando...',
    })

    this.closeModal()
    this.loading = true

    const action = this.contact.id
      ? 'BusinessModule/PostEditCompanyContact'
      : 'BusinessModule/PostCompanyContact'

    const contactName = this.contact.name
    const contactId = this.contact.id
    this.$store
      .dispatch(action, { id: this.id, contact: this.contact })
      .then(() => {
        let message
        if (contactId) {
          message = `Usuario ${contactName} editado con éxito`
        } else {
          message = `Usuario ${contactName} añadido con éxito`
        }
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: message,
          timeout: 2000,
        })

        let contactRoles = ''

        if (this.contact.roles) {
          contactRoles = this.contact.roles.toString()
            ? this.contact.roles.toString()
            : 'No Contactos'
        }
        this.$mixpanel.track('Responsable Agregado', {
          'Email Responsable': this.contact.email,
          Roles: contactRoles,
        })
        this.$store
          .dispatch('BusinessModule/getContacts', this.id)
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })

        this.$emit('contactCreated', { created: true, email: this.contact.email })
      })
      .catch(() => {
        this.loading = false
      })
  }

  @Watch('showDialog')
  show(val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose(): void {
    if (this.dialog === false) {
      this.contact = {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        roles: [],
        send_invitation: true,
      }
      this.$emit('close')
    }
  }
  @Watch('selectedContact')
  changeContact(): void {
    this.contact = { ...this.contact, ...this.selectedContact }
  }

  private closeModal() {
    this.dialog = false
    this.$emit('close')
  }
}
